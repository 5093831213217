<template>
  <div class="search">
    <el-form
      :model="form"
      class="demo-form-inline"
      size="small"
      inline
      ref="searchForm"
    >
      <div class="top">
        <el-form-item label="关键词：" class="form-item" prop="userName">
          <el-input
            placeholder="请输入员工姓名"
            class="form-item-input"
            v-model="form.userName"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作类型：" class="form-item" prop="logType">
          <el-select
            v-model="form.logType"
            placeholder="请选择"
            class="form-item-select"
          >
            <el-option label="全部" value></el-option>
            <!-- <el-option label="登录" :value="1"></el-option> -->
            <el-option label="新增" :value="2"></el-option>
            <el-option label="编辑" :value="3"></el-option>
            <el-option label="删除" :value="4"></el-option>
            <!-- <el-option label="查看" :value="5"></el-option> -->
            <!-- <el-option label="查看产品详情" :value="6"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="操作时间：">
          <el-date-picker
            v-model="time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item class="form-item" label-width="30px">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
          >查询</el-button>
          <el-button size="small" @click="handleRest">重置</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'Search',
  components: {},
  data () {
    return {
      time: "",
      form: {
        createTimeStart: '',
        createTimeEnd: '',
        logType: '',
        userName: ''
      }
    };
  },
  watch: {
    time (newVal, oldVal) {
      if (newVal) {
        this.form.createTimeStart = dayjs(newVal[0]).format('YYYY-MM-DD HH:mm:ss');
        this.form.createTimeEnd = dayjs(newVal[1]).format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.form.createTimeStart = '';
        this.form.createTimeEnd = '';
      }
    },
  },
  methods: {
    handleSearch () {
      this.$emit('search', this.form);
    },
    handleRest () {
      this.time = '';
      this.form.createTimeStart = '';
      this.form.createTimeEnd = '';
      this.$refs.searchForm.resetFields();
      this.$emit('reset', this.form);
    }
  }
};
</script>
<style lang="less" scoped>
@import "./search.less";
</style>
