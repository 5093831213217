<template>
  <section class="log-wrapper">
    <search @search="handleSearch" @reset="handleRest" />
    <div class="table-wrapper">
      <vxe-grid
        :ref="tableRef"
        :rowId="rowId"
        border
        :data="dataSource"
        :columns="tableColumn"
        v-bind="tableOptions"
        size="small"
        v-loading="loading"
        @checkbox-change="handleCheckboxChange"
        @checkbox-all="handleCheckboxAll"
        :row-config="{ isHover: true }"
      >
        <template #toolbar_buttons>
          <el-button size="small" type="default" @click="handleExport">导出</el-button>
        </template>
        <template #logType="{ row }">
          <span>{{ row.logType | operateText }}</span>
        </template>
        <template #operationContent="{ row }">
          <el-tooltip effect="dark" :content="row.operationContent" popper-class="my-tooltip-class">
            <div class="my-tooltip">{{ row.operationContent }}</div>
          </el-tooltip>
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </div>
  </section>
</template>

<script>
//  日志
import Search from './components/search/index.vue';
import IPagination from '@/components/i-pagination/index.vue';
import TableListMixin from '@/minixs/TableListMinix';
import { getLogList } from '@/api/log';

const operateTypeList = ['登录', '新增', '编辑', '删除', '查看', '', '', '', '', '', '', '', ''];
export default {
  name: 'LogList',
  mixins: [TableListMixin],
  components: {
    Search,
    IPagination
  },
  data() {
    return {
      rowId: 'logId',
      tableRef: 'LogTable',
      url: {
        list: 'misc-web-api/admin/logs/list'
      },
      tableColumn: [
        { type: 'checkbox', width: 50 },
        { field: 'userId', title: '员工ID' },
        { field: 'userName', title: '员工姓名' },
        { field: 'departmentNames', title: '所在部门' },
        { field: 'logType', title: '操作类型', slots: { default: 'logType' } },
        { field: 'operationModule', title: '操作模块' },
        {
          field: 'operationContent',
          title: '操作内容',
          slots: { default: 'operationContent' }
          // showOverflow: 'tooltip'
        },
        { field: 'createTime', title: '操作时间' }
      ],
      operateTypeList,
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons'
          }
        },
        checkboxConfig: {
          // checkField:'rankId',
          checkRowKeys: [],
          reserve: true // 保留勾选状态
        }
      }
    };
  },
  filters: {
    operateText(val) {
      return operateTypeList[val - 1];
    }
  },
  // computed: {
  //   operateText() {
  //     return (val) => this.operateTypeList.find((item) => item.value === val).label;
  //   }
  // },
  methods: {
    // 导出
    async handleExport() {
      if (this.checkedRows.length === 0) {
        this.$message.warning('至少选择一条数据');
        return;
      }
      const logIds = this.checkedRows.map((item) => item.logId);
      const params = {
        data: {
          export: 1,
          logIds
        }
      };
      const res = await getLogList(params, {
        responseType: 'blob',
        headers: { 'Content-Type': 'application/json; application/octet-stream' }
      });
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(new Blob([res]), '日志列表.xls');
      } else {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', '日志列表.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(url); // 释放掉blob对象
      }
    }
  }
};
</script>

<style scoped lang="less">
.log-wrapper {
  margin-top: 16px;
  .table-wrapper {
    background: #fff;
    padding: 20px 20px;
    margin-top: 20px;
  }
}
</style>
<style lang="less">
.log-wrapper {
  .my-tooltip {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.my-tooltip-class {
  max-width: 300px;
}
</style>
