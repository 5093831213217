
import request from '@/utils/request';


export function getLogList(data, options = {}) {
  return request({
    url: 'misc-web-api/admin/logs/list',
    method: 'POST',
    data,
    ...options
  });
}

export default {}
